export const trackProduct = function(label, callback = () => {}) {
  window.gtag("event", "click", {
    event_category: "product-purchase",
    event_label: label,
    transport_type: "beacon",
    event_callback: function() {
      callback()
    },
  })
}

export const trackStockist = function(
  label,
  url,
  callback = () => (document.location = url)
) {
  window.gtag("event", "click", {
    event_category: "product-purchase",
    event_label: label,
    transport_type: "beacon",
    event_callback: function() {
      callback()
    },
  })
}
