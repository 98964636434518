import bootsLogo from "../images/stockists/boots.png"
import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
import asdaLogo from "../images/stockists/asda.png"
import morrisonsLogo from "../images/stockists/morrisons.png"
import amazonLogo from "../images/stockists/amazon.png"
//import wilkoLogo from "../images/stockists/wilko.png"
import ocadoLogo from "../images/stockists/ocado.png"
import saversLogo from "../images/stockists/savers.png"
import BandMLogo from "../images/stockists/bandm.png"
import homebargainsLogo from "../images/stockists/homebargains.png"
import bodycareLogo from "../images/stockists/bodycare.png"
import coopLogo from "../images/stockists/coop.png"
import waitroseLogo from "../images/stockists/waitrose.png"

export const json = [
  // {
  //   product: "Blistex Relief Cream. ",
  //   stockists: [
  //     {
  //       stockistName: "Superdrug",
  //       stockistImage: superdrugLogo,
  //       stockistLink:
  //         "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Relief-Cream-5g/p/512958",
  //       classModifier: "",
  //       showLink: "true",
  //     },
  //     {
  //       stockistName: "Amazon",
  //       stockistLink: "https://www.amazon.co.uk/dp/B09MTXZY2Y",
  //       stockistImage: amazonLogo,
  //       classModifier: "",
  //       showLink: "true",
  //     },
  //     {
  //       stockistName: "B&M",
  //       stockistLink: "https://www.bmstores.co.uk",
  //       stockistImage: BandMLogo,
  //       classModifier: "",
  //       showLink: "false",
  //     },
  //     {
  //       stockistName: "Home Bargains",
  //       stockistLink: "https://www.homebargains.co.uk",
  //       stockistImage: homebargainsLogo,
  //       classModifier: "no-border-left",
  //       showLink: "false",
  //     },
  //     {
  //       stockistName: "Bodycare",
  //       stockistLink:
  //         "https://www.bodycareonline.co.uk/product/blistex-relief-cream-5g/",
  //       stockistImage: bodycareLogo,
  //       classModifier: "",
  //       showLink: "",
  //     },
  //     {
  //       stockistName: "Morrisons",
  //       stockistLink:
  //         "https://groceries.morrisons.com/products/blistex-relief-cream-268600011",
  //       stockistImage: morrisonsLogo,
  //       classModifier: "no-border-left",
  //       showLink: "true",
  //     },
  //     {
  //       stockistName: "Savers",
  //       stockistImage: saversLogo,
  //       stockistLink:
  //         "https://www.savers.co.uk/Skin-Care/Lip-Care/Dry-%26-Chapped-Lip-Treatments/Blistex-Relief-Cream-5g/p/512958",
  //       classModifier: "border-bottom",
  //       showLink: "true",
  //     },
  //     {
  //       stockistName: "Waitrose",
  //       stockistImage: waitroseLogo,
  //       stockistLink:
  //         "https://www.waitrose.com/ecom/products/blistex-relief-cream/766593-777410-777411",
  //       classModifier: "",
  //       showLink: "true",
  //     },
  //   ],
  // },
  {
    product: "Cold Sore Cream. ",
    stockists: [
      {
        stockistName: "Boots",
        stockistLink:
          "https://www.boots.com/blistex-cold-sore-cream-2g-10243453",
        stockistImage: bootsLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tesco",
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/299055788",
        stockistImage: tescoLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Superdrug",
        stockistLink:
          "https://www.superdrug.com/Hair-Brushes-%26-Combs/Detangling-Hair-Brushes/Blistex-Coldsore-Cream-24g/p/757150",
        stockistImage: superdrugLogo,
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk",
        stockistImage: amazonLogo,
      },
    ],
  },
  // {
  //   product: "Lip Brilliance.",
  //   disclaimer: "",
  //   stockists: [
  //     {
  //       stockistName: "Amazon",
  //       stockistLink: "https://www.amazon.co.uk/dp/B07J26NBXV",
  //       stockistImage: amazonLogo,
  //       classModifier: "",
  //       showLink: "true",
  //     },
  //     {
  //       stockistName: "Savers",
  //       stockistImage: saversLogo,
  //       stockistLink:
  //         "https://www.savers.co.uk/Blistex/Blistex-Lip-Brilliance-3-7g/p/326171",
  //       classModifier: "",
  //       showLink: "true",
  //     },
  //   ],
  // },
  {
    product: "Intensive Moisturiser.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Boots",
        stockistImage: bootsLogo,
        stockistLink:
          "https://www.boots.com/blistex-intensive-moisturiser-hydrating-lip-cream-spf10-5g-10003619",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tesco",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/250653921",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Superdrug",
        stockistImage: superdrugLogo,
        stockistLink:
          "https://www.superdrug.com/Blistex/Blistex-Intensive-Moisturiser-Hydrating-SPF-10-5g/p/742619",
        classModifier: "border-bottom",
        showLink: "true",
      },
      {
        stockistName: "Morrisons",
        stockistImage: morrisonsLogo,
        stockistLink:
          "https://groceries.morrisons.com/webshop/product/Blistex-Lipcare-Intensive-Moisuriser-/268599011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink:
          "https://www.sainsburys.co.uk/shop/gb/groceries/lip-care-448415-44/blistex-intensive-moisturiser-spf-10-5g",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Savers",
        stockistImage: saversLogo,
        stockistLink:
          "https://www.savers.co.uk/Skin-Care/Lip-Care/Dry-%26-Chapped-Lip-Treatments/Blistex-Intensive-Moisturiser-SPF-10-5g/p/742619",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Bodycare",
        stockistLink:
          "https://www.bodycareonline.co.uk/product/blistex-intensive-moisturiser-5g/",
        stockistImage: bodycareLogo,
        classModifier: "",
        showLink: "",
      },
    ],
  },
  {
    product: "Intensive Moisturiser Cherry.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B07H7T9P7M",
        stockistImage: amazonLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Bodycare",
        stockistImage: bodycareLogo,
        stockistLink:
          "https://www.bodycareonline.co.uk/product/blistex-intensive-moisturiser-cherry-6ml/",
        classModifier: "",
        showLink: "",
      },

      {
        stockistName: "Tesco",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/295036178",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink: "https://www.sainsburys.co.uk",
        classModifier: "",
        showLink: "",
      },
      {
        stockistName: "Savers",
        stockistImage: saversLogo,
        stockistLink:
          "https://www.savers.co.uk/Skin-Care/Lip-Care/Lip-Balms/Blistex-Intensive-Moisturiser-Cherry/p/830979",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    product: "MedPlus Jar.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Boots",
        stockistImage: bootsLogo,
        stockistLink:
          "https://www.boots.com/blistex-medplus-repairing-lip-balm-spf15-7ml-10165250",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Bodycare",
        stockistImage: bodycareLogo,
        stockistLink:
          "https://www.bodycareonline.co.uk/product/blistex-medplus-7ml/",
        classModifier: "",
        showLink: "",
      },
      {
        stockistName: "Tesco",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/264866947",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Superdrug",
        stockistImage: superdrugLogo,
        stockistLink:
          "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Med-Plus-SPF-15-7ml/p/746633",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink:
          "https://www.sainsburys.co.uk/gol-ui/Product/blistex-med-plus",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B09HS8WKPR",
        stockistImage: amazonLogo,
        classModifier: "no-border-left",
        showLink: "true",
      },
      {
        stockistName: "Savers",
        stockistLink:
          "https://www.savers.co.uk/Blistex/Blistex-Med-Plus-SPF15-7ml/p/746633?singleResultSearchPage=true",
        stockistImage: saversLogo,
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    product: "MedPlus Stick.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Homebargains",
        stockistLink: "https://home.bargains/",
        stockistImage: homebargainsLogo,
        showLink: "false",
      },
      {
        stockistName: "Superdrug",
        stockistLink: "https://www.superdrug.com/health/medicated-skin/medicated-skin-treatments/blistex-med-plus-stick/p/840480",
        stockistImage: superdrugLogo,
        showLink: "true",
      },
    ],
  },
  {
    product: "Ultra SPF 50+.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B09HSB9HPN",
        stockistImage: amazonLogo,
        showLink: "true",
      },
    ],
  },
  {
    product: "Lip Infusions - Nourish.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B09HSC2K85",
        stockistImage: amazonLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Superdrug",
        stockistImage: superdrugLogo,
        stockistLink:
          "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Lip-Infusions---Nourish/p/805974?singleResultSearchPage=true",
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    product: "Lip Infusions - Hydration.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Boots",
        stockistImage: bootsLogo,
        stockistLink:
          "https://www.boots.com/blistex-lip-infusions-hydration-10301404",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tesco",
        stockistImage: tescoLogo,
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/308826894",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Ocado",
        stockistImage: ocadoLogo,
        stockistLink:
          "https://www.ocado.com/products/blistex-lip-infusions-hydration-552888011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Morrisons",
        stockistImage: morrisonsLogo,
        stockistLink:
          "https://groceries.morrisons.com/products/blistex-lip-infusions-hydration-spf-15-559044011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B09HS9RK11",
        stockistImage: amazonLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Sainsbury's",
        stockistLink:
          "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-hydration-spf15-37g",
        stockistImage: sainsburysLogo,
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    product: "Lip Infusions - Soothing.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Sainsbury's",
        stockistImage: sainsburysLogo,
        stockistLink:
          "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-soothing-37g",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Ocado",
        stockistImage: ocadoLogo,
        stockistLink:
          "https://www.ocado.com/products/blistex-lip-infusions-soothing-552889011",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistLink: "https://www.amazon.co.uk/dp/B09HSBHM2W",
        stockistImage: amazonLogo,
        classModifier: "",
        showLink: "true",
      },
    ],
  },
  {
    product: "Lip Infusions - Restore.",
    disclaimer: "",
    stockists: [
      {
        stockistName: "Boots",
        stockistImage: bootsLogo,
        stockistLink:
          "https://www.boots.com/blistex-lip-infusions-restore-lip-balm-10317368",
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Amazon",
        stockistLink:
          "https://www.amazon.co.uk/Blistex-Infusions-Restore-Chapped-Coconut/dp/B09X61KCKS/ref=sr_1_6?crid=2NQHEXMB0T051&keywords=blistex+lip+infusions&qid=1654701142&sprefix=blistex+lip+in%2Caps%2C74&sr=8-6",
        stockistImage: amazonLogo,
        classModifier: "",
        showLink: "true",
      },
      {
        stockistName: "Tesco",
        stockistLink:
          "https://www.tesco.com/groceries/en-GB/products/314600795",
        stockistImage: tescoLogo,
        classModifier: "",
        showLink: "true",
      },
    ],
  },
]
