import React, { Component } from "react"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import Instagram from "../components/global/instagram"
import { trackProduct, trackStockist } from "../utils/ga-tracking-purchase"
import { json } from "../data/json"

const FilterOption = ({ options, selected, onChange }, index) => {
  const product = json.filter(item => item.product === selected)
  return (
    <>
      <div className="where-to-buy__filter-option" key={index}>
        {options.map((item, index) => (
          <>
            <div key={index} className="where-to-buy__filter-option-container">
              <input
                type="radio"
                name="product"
                id={item.product}
                value={item.product}
                key={index}
                checked={selected === item.product}
                onChange={onChange}
              />
              <label htmlFor={item.product}>
                {item.product}
                {/* {item.disclaimer !== "" && (
                  <span
                    className={selected === item.product ? "show" : "hidden"}
                  >
                    *
                  </span>
                )} */}
              </label>
            </div>
          </>
        ))}
      </div>
      {product[0].disclaimer}
    </>
  )
}

class WhereToBuy extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: json[0].product,
      selectedStockists: json[0].stockists,
      stockistsHeight: 0,
    }
  }

  componentDidMount() {
    const stockistContainer = document.querySelector(".where-to-buy")
    this.setState({ stockistsHeight: stockistContainer.clientHeight })
  }

  handleOnChange(e) {
    const productStockists = json.filter(
      item => item.product === e.target.value
    )

    this.setState({
      selectedOption: e.target.value,
      selectedStockists: productStockists[0].stockists,
    })
  }

  render() {
    return (
      <Layout noHeaderInLayout={false}>
        <SEO title="where to buy" />
        <div className="where-to-buy container-left container-right">
          <div className="privacy">
            <h1>where to buy</h1>
          </div>
          <nav className="where-to-buy__filter">
            <h2>filter by product</h2>
            <FilterOption
              options={json}
              onChange={e => this.handleOnChange(e)}
              selected={this.state.selectedOption}
            />
          </nav>
          <div
            className="where-to-buy__stockists"
            style={{ height: this.state.stockistsHeight }}
          >
            <TransitionGroup>
              <CSSTransition
                key={this.state.selectedOption}
                timeout={1000}
                classNames="stockist"
              >
                <div className="where-to-buy__stockist-container stockist-enter-done">
                  {this.state.selectedStockists.map((stockist, index) => (
                    <div
                      key={index}
                      className={`where-to-buy__stockist ${stockist.classModifier}`}
                    >
                      {stockist.stockistLink !== "" && (
                        <a
                          href={stockist.stockistLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            pointerEvents:
                              stockist.showLink !== "true" ? "none" : "all",
                          }}
                          onClick={() =>
                            trackProduct(
                              this.state.selectedOption,
                              trackStockist(
                                stockist.stockistName,
                                stockist.stockistLink,
                                () => {
                                  console.log(
                                    "stockist sent",
                                    stockist.stockistName,
                                    stockist.stockistLink,
                                    "product name: ",
                                    this.state.selectedOption
                                  )
                                }
                              )
                            )
                          }
                        >
                          <img
                            src={stockist.stockistImage}
                            alt={stockist.stockistName}
                            title={stockist.stockistName}
                          />
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </div>
        {/* <Instagram /> */}
      </Layout>
    )
  }
}

export default WhereToBuy
